<template>
  <section class="m-wallet-card m-scroll">

    <!-- 头部导航 -->
    <m-header title="Card information"></m-header>

    <div class="form">

      <div class="input-box">
        <span>First Name</span>
        <input v-model="firstname" type="text" placeholder="Please enter">
      </div>

      <div class="input-box">
        <span>Last Name</span>
        <input v-model="lastname" type="text" placeholder="Please enter">
      </div>

      <p class="bank-info">Bank information</p>
      <p class="bank-tips">*This information is only used for bank transfers</p>
      <div class="card-info">

        <div class="t-number">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[0].value"   @focus="handleFocus($event,0)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[1].value"   @focus="handleFocus($event,1)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[2].value"   @focus="handleFocus($event,2)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[3].value"   @focus="handleFocus($event,3)">
          <input type="number"                    name="cardnumber" v-model="cardId[4].value"   @focus="handleFocus($event,4)">
        </div>

        <div class="i-number">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[5].value"   @focus="handleFocus($event,5)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[6].value"   @focus="handleFocus($event,6)">
          <input type="number"                    name="cardnumber" v-model="cardId[7].value"   @focus="handleFocus($event,7)">
        </div>

        <div class="a-number">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[8].value"   @focus="handleFocus($event,8)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[9].value"   @focus="handleFocus($event,9)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[10].value"   @focus="handleFocus($event,10)">
          <input type="number"                    name="cardnumber" v-model="cardId[11].value"   @focus="handleFocus($event,11)">
          <span class="connection"><span></span></span>
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[12].value"   @focus="handleFocus($event,12)">
          <input type="number" class="input-left" name="cardnumber" v-model="cardId[13].value"   @focus="handleFocus($event,13)">
          <input type="number"                    name="cardnumber" v-model="cardId[14].value"   @focus="handleFocus($event,14)">
        </div>
      </div>

      <div class="card-info">
        <div class="card-tips">Transit Number</div>
        <div class="card-tips">Institiution Number</div>
        <div class="card-tips">Account Number</div>
      </div>
      



      <div class="footer">
        <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Add your card</van-button>
        <p class="times"><span>*</span>Please fill in the bank information so that we can transfer (note that it is not the bank card number),This information can be viewed on Void Cheque or consult your bank</p>
      </div>

    </div>
    
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
import { card_add } from '@/api/zh/mine.js'

export default {
  name:'BindEmail',
  components:{ MHeader },
  data(){
    return {
      firstname: '',
      lastname: '',
      cardId:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}],
    }
  },

  

  methods:{

    handleFocus(e,index){
        let cardInputs = document.getElementsByName('cardnumber');
        const that = this;
        cardInputs[index].onkeyup = function(event) {
            if (index != 14 && event.keyCode != 8) {
                cardInputs[index+1].focus();
            }
            if(that.cardId[index].value.length > 1){
                that.cardId[index].value = that.cardId[index].value.slice(0,1)
            }
        }
        cardInputs[index].onkeydown = function(event) {
            if (index != 0 && event.keyCode == 8) {
                if(that.cardId[index].value == ""){
                    cardInputs[index-1].focus();
                }
                
            }
        }
    },
    
    onNext(){
      let params = {}
      if(this.firstname == ''){
        this.$notify({ type: 'warning', message: 'Please complete the name'})
        return false
      }else {
        params.first_name = this.firstname
      }
      if(this.lastname == ''){
        this.$notify({ type: 'warning', message: 'Please complete the last name'})
        return false
      }else {
        params.last_name = this.lastname
      }

      let bol = false, num = []
      this.cardId.forEach(e => {
        num.push(e.value)
        if(e.value == ''){
          bol = true
        }
      })
      if(bol) {
        this.$notify({ type: 'warning', message: 'Please complete your banking information'})
        return false
      }else{
        params.transit_number      = num.slice(0,5).join('')
        params.institiution_number = num.slice(5,8).join('')
        params.account_number      = num.slice(8).join('')
      }
      this.$store.state.load = true
      card_add(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg})
          this.$router.go(-1)
        }
      }).finally( () => {
        this.$store.state.load = false
      })

      
    },

    

   
  },
}
</script>

<style lang="less" scoped>
.m-wallet-card {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .form {
    margin-top: 10px;
    background-color: #fff;
    padding: 28px  16px  20px;
  }
  .input-box {
    width: 100%;
    height: 52px;
    position: relative;
    border:  2px solid #3284FA;
    margin-bottom: 27px;
    border-radius: 5px;
    >span {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      background: #fff;
      padding: 0 16px;
      position: absolute;
      left: 20px;
      top: -16px;
    }
    >input {
      outline: none;
      border: none;
      width: 100%;
      height: 48px;
      padding-left: 21px;
    }
    >input::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #999;
    }
  }
  .bank-info {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding-bottom: 12px;
  }
  .bank-info:last-of-type {
    padding-top: 30px;
  }
    .bank-tips {
    font-size: 12px;
    
    font-weight: 300;
    color: #0854BF;
    line-height: 12px;
    margin: 4px 0 12px;
  }
  .card-info {
    display: grid;
    grid-template-columns: 96px 58px 148px;
    grid-template-rows: 32px;
    grid-column-gap: 16px ;
    input {
      width: 16px ;
      height: 32px;
      outline: none;
      border: 1px solid #3284FA;
      border-radius: 3px;
      text-align: center;
    }
    .input-left {
      margin-right: 4px;
    }
    .connection {
      width: 13px;
      height: 32px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      >span {
        display: inline-block;
        width:7px;
        height:2px;
        background:rgba(50,132,250,1);
        position: absolute;
        top: 15px;
        left: 3px;
      }
    }
    .card-tips {
      font-size: 12px;
      font-weight: 300;
      color: #666;
      line-height: 17px;
      padding-top: 4px;
    }
  }
  .upload-wrap {
      width: 100%;
      height: 105px;
      display: flex;
      align-items: center;
      .upload-box {
        width: 102px;
        height: 102px;
        margin-right: 28px;
        >div {
          background:rgba(255,255,255,1);
          border-radius:5px;
          border: 1px dashed #B5B5B5;
          width: 102px;
          height: 102px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          >img {
            width: 22px;
            height: 22px;
          }
          >span {
            font-size: 16px;
            font-weight: 400;
            color: #999;
            padding-top: 8px;
          }
        }
        &>img {
          width: 102px;
          height: 102px;
          vertical-align: top;
        }
      }
  }

  .footer {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    .times {
      font-size: 12px;
      font-weight: 400;
      color: #999;
      margin-top: 8px;
      >span {
        color: #ED2A24;
      }
    }
  }

}
</style>